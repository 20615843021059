@import '../../../../styles/font.scss';
@import '../../../../styles/colors.scss';

.btn {
	padding: 1.6rem;
	border-radius: 0.4rem;

	&.outlined {
		border: 1px solid $primary-blue;
		background-color: white;
		color: $primary-blue;

		&.disabled {
			border: 0.05rem solid $grey-medium;
			color: $grey-medium;
		}

		&:hover:enabled {
			background-color: #f9f9f9;
		}
	}

	&.default {
		background-color: $primary-blue;
		color: $neutral-main;
		border: none;

		&.disabled {
			background-color: $grey-medium;
		}

		&:hover:enabled {
			background-color: $hover-blue;
		}
	}
}
