@import '../../../styles/colors.scss';
@import '../../../styles/font.scss';

.event-card-content {
	border-radius: 0.4rem;
	padding: 2rem;

	&__description {
		margin: 1.2rem 0;
		display: flex;
		align-items: flex-start;

		img {
			margin-right: 0.8rem;
		}
	}

	&__header {
		button {
			color: $primary-blue;
			margin-left: 1rem;

			img {
				margin-right: 0.5rem;
			}
		}
	}
}
.marketing-details-card {
	padding: 8px 0px 8px 0px;
	row-gap: 0.5rem;
	button {
		color: $primary-blue;
		height: fit-content;
	}
	h4,
	p {
		margin: 0;
	}

	button > div {
		font-size: 1.4rem;
	}
}

.action-popup {
	position: absolute;
	border: none;
	z-index: 3;

	button {
		height: 5rem;
		border: 1px solid $primary-blue;
		background: $neutral-main;
		font: normal $font-medium $family-gibson;
		text-align: left;

		&:first-child {
			border-radius: 0.4rem 0.4rem 0 0;
		}

		&:last-child {
			border-radius: 0 0 0.5rem 0.5rem;
		}

		&:hover {
			background-color: $grey-light;
		}

		span {
			position: absolute;
			left: 2.5rem;
		}
	}
}
