@import '../../../../styles/colors.scss';

.card {
	border-radius: 0.4rem;
	padding: 2rem;

	&__header {
		button {
			color: $primary-blue;
			margin-left: 2rem;

			img {
				margin-right: 0.5rem;
			}
		}
	}

	&__description {
		margin: 1.2rem 0;
		display: flex;
		align-items: flex-start;
	}
}
