@import '../../../../styles/colors.scss';

.checkbox-container {
	display: flex;
	flex-direction: row;
	color: $primary-black;

	.checkbox-button {
		background: transparent;
		border-style: none;
		display: flex;
		align-items: center;
		padding-left: 0;

		.checkbox {
			margin: 0;
			height: 1.8rem;
			width: 1.8rem;
			cursor: pointer;
			accent-color: $primary-blue;
		}
	}
	.checkbox-description {
		margin-top: 0.2rem;
		margin-left: 0.5rem;
		h3 {
			margin: 0;
		}

		h4 {
			margin: 0;
			color: $grey-dark;
			font-weight: normal;
		}
	}
}
