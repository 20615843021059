@import './colors.scss';
@import './font.scss';

$headerHeight: 4rem;
$topBottomPaddingForCells: 0.8rem 0;

.scrollable-table {
	padding: 0.7rem;
	padding-bottom: 0;
	border-radius: 0.4rem;

	* {
		box-sizing: border-box;
	}

	> div {
		position: relative;
		height: 100%;
		padding-top: $headerHeight; /*this determines column header height*/

		&:before,
		> div:after {
			top: 0;
			background: $grey-light; /*column header background color*/
			content: '';
			position: absolute;
			z-index: -1;
			width: 100%;
			height: $headerHeight;
			left: 0;
		}

		> div {
			max-height: 100%;
			overflow: overlay;
			overflow-x: hidden;
		}

		table {
			width: 100%;
			overflow: hidden;
			margin-top: -$headerHeight; // this determines the positioning of the header columns
			padding: 0 1.5rem;

			thead {
				white-space: nowrap;
				line-height: 0;
			}

			tr {
				display: table-row;
				vertical-align: middle;
				height: $headerHeight;

				> * > div,
				*:before {
					position: absolute;
					white-space: pre-wrap;
				}

				&:last-child > td {
					border-bottom: none;
				}

				td {
					border-bottom: 0.1rem solid #dadada;
				}
			}

			tr > *,
			td {
				padding: $topBottomPaddingForCells; /*cell padding*/
				word-wrap: break-word;
				overflow-wrap: break-word;

				.action-button {
					font: normal 14px $family-gibson;

					&:hover {
						background-color: $grey-light;
					}
				}
			}

			th {
				min-width: 5rem;
			}
		}
	}
}
