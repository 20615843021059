.paginator {
	&__arrow-button {
		background: none;
		padding: 0;
		border: none;

		&:hover {
			cursor: pointer;
		}
		&:disabled {
			cursor: not-allowed;
			filter: invert(50%) sepia(2%) saturate(3%) hue-rotate(343deg)
				brightness(88%) contrast(92%);
		}
	}

	&__left-arrow-icon {
		transform: rotate(90deg);
	}

	&__right-arrow-icon {
		transform: rotate(-90deg);
	}
}
