.select-wrapper {
	position: relative;

	.select {
		// Get rid of default chevron
		-moz-appearance: none; /* Firefox */
		-webkit-appearance: none; /* Safari and Chrome */
		appearance: none;
		border-radius: 0.4rem;
		cursor: pointer;
	}

	.caret-container {
		position: absolute;
		right: 2rem;
		top: 30%;
	}

	&--small {
		@extend .select-wrapper;

		.caret-container {
			position: absolute;
			right: 0.75rem;
			top: 50%;
			transform: scale(0.7) translateY(-65%);
		}
	}
}
