@import '../../../../styles/colors.scss';

.icon-button {
	img {
		margin-right: 0.5rem;
	}

	&:disabled {
		color: $grey-dark;

		img {
			filter: brightness(0) saturate(100%) invert(45%) sepia(9%) saturate(18%)
				hue-rotate(341deg) brightness(96%) contrast(86%);
		}
	}
}
