.form-width {
	width: 80rem;
}

.table-width {
	width: 110rem;
}

.w-100 {
	width: 100%;
}
.w-50 {
	width: 50%;
}

.h-100 {
	height: 100%;
}
