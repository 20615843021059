#root,
.App,
.app-content {
	height: 100%;
	padding-bottom: 2rem;
}

*::-webkit-scrollbar-thumb {
	background-color: #9e9d9d;
	border: 0.5rem solid transparent;
	border-radius: 0.8rem;
	background-clip: padding-box;
}

*::-webkit-scrollbar {
	width: 1.4rem;
	background-color: transparent;
}
