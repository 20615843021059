$primary-blue: #003865;
$secondary-blue: rgba(0, 56, 101, 0.3);
$hover-blue: #024e8a;
$primary-black: #233746;

$neutral-main: #ffffff;

$grey-dark: #737373;
$grey-medium: #bdbcbc;
$grey-light: #f0f0f0;

$input-border: 1px solid $grey-dark;

$error-main: #d32f2f;

.primary-blue {
	color: $primary-blue;
}

.neutral {
	color: $neutral-main;
}

.grey-dark {
	color: $grey-dark;
}

.color-disable {
	color: $grey-dark;
}

.blue-background {
	background-color: $primary-blue;
	color: $neutral-main;
	border: none;
}

.light-blue-border {
	border-color: $secondary-blue;
}
