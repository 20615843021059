@import './styles/font.scss';
@import './styles/colors.scss';
@import './styles/flex.scss';
@import './styles/grid.scss';
@import './styles/spacing.scss';
@import './styles/table.scss';
@import './styles/utils.scss';

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

@font-face {
	font-family: 'Gibson';
	src: url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('woff'),
		url('https://use.typekit.net/af/299bdc/00000000000000003b9b0ba5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 400;
	font-stretch: normal;
}

@font-face {
	font-family: 'Gibson Bold';
	src: url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff2'),
		url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('woff'),
		url('https://use.typekit.net/af/1d6761/00000000000000003b9b0ba9/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3')
			format('opentype');
	font-display: auto;
	font-style: normal;
	font-weight: 700;
	font-stretch: normal;
}

html {
	// Allows for base 10 REM. 1.6rem = 16px
	font-size: 62.5%;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	box-sizing: inherit;
}

body {
	font-size: $font-medium;
	margin: 0;
	height: 100%;
	color: $primary-black;
	font-family: 'Gibson', 'Bebas Neue', 'Noto Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: overlay;

	button {
		cursor: pointer;

		&:disabled {
			cursor: default;
		}
	}

	input:focus,
	select:focus,
	textarea:focus {
		outline-color: $secondary-blue;
		background-color: transparent;
	}

	.input-error {
		border: 2px solid $error-main !important;

		&:focus {
			outline-color: $error-main !important;
		}
	}

	input:-webkit-autofill,
	input:-webkit-autofill:hover,
	input:-webkit-autofill:focus,
	input:-webkit-autofill:active {
		-webkit-box-shadow: 0 0 0 80px $neutral-main inset !important;
		box-shadow: 0 0 0 80px $neutral-main inset !important;
	}
}

body.modal-open {
	overflow-y: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
