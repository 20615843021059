.flex-col {
	display: flex;
	flex-direction: column;
}

.flex-row {
	display: flex;
	flex-direction: row;
}

.align-center {
	align-items: center;
}

.justify-center {
	justify-content: center;
}

.justify-between {
	justify-content: space-between;
}

.row {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;

	& > :not(:first-child) {
		margin-left: 2rem;
	}
}

.gap-1 {
	row-gap: 1rem;
	column-gap: 1rem;
}
.gap-2 {
	row-gap: 2rem;
	column-gap: 2rem;
}
