@import '../../../styles/colors.scss';

.marketing-details {
	&__promotion-section {
		position: relative;
		&__card {
			position: relative;
			border: $input-border;
			border-radius: 0.4rem;
			padding: 1.5rem 2rem;
		}

		&__button {
			margin: -1rem 0 0 auto;
		}
	}

	&__input-list-section {
		position: relative;

		&__button {
			margin: -1rem 0 0 auto;
		}
	}

	&__button {
		position: absolute;
		top: 0.5rem;
		right: 0.5rem;

		&:disabled > img {
			filter: invert(48%) sepia(0%) saturate(1%) hue-rotate(27deg)
				brightness(93%) contrast(91%);
		}
	}

	.delete-rule {
		top: 53%;
		right: 1rem;
	}

	.break-section {
		width: 100%;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}
