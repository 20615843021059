@import '../../../styles/colors.scss';

.hole-details-entry {
	border: $input-border;
	padding: 1.5rem;
	border-radius: 0.4rem;
	width: 100%;

	h3 {
		margin: 0;
		margin-right: 1rem;
	}

	.lat-long-inputs {
		justify-content: space-between;
		margin-top: 0.5rem;

		input {
			width: 100%;
		}
	}
}
