@import '../../../../styles/colors.scss';

.home-section {
	background-image: url('../../../../assets/images/home-screen-background.svg');
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: 0;

	&__blur {
		position: fixed;
		background-color: rgb(0, 0, 00, 0.56);
		backdrop-filter: blur(0.4rem);
	}
}

.home-button {
	width: 18rem;
	height: 6.4rem;
	border: 0.11rem solid $neutral-main;
	border-radius: 0;
	color: $neutral-main;
	background: transparent;
	letter-spacing: 0.13rem;
	z-index: 2;

	&:hover:enabled {
		background-color: $neutral-main;
		color: $primary-blue;
	}
	&:disabled {
		border: 0.11rem solid $grey-dark;
		color: $grey-dark;
	}
}
