@import '../../../../styles/colors.scss';

.react-datepicker-wrapper {
	width: 100% !important;
}

.react-datepicker {
	font-size: 1.3rem !important;
}

.react-datepicker__current-month {
	font-size: 1.5rem !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
	width: 2.5rem !important;
	line-height: 2.5rem !important;

	&--selected,
	&--selected:hover {
		background-color: $primary-blue;
	}
}

.datepicker-input-calendar {
	width: 100%;
	background-color: transparent;
	background: url('../../../../assets/images/calendar.svg') no-repeat right;
	background-origin: content-box;
}
