@import '../../../../styles/colors.scss';

.form-title {
	margin: 4.1rem 0 4.1rem 0;

	h1 {
		color: $primary-blue;
		margin: 0;
		text-align: left;
	}

	hr {
		width: 5.1rem;
		height: 0.5rem;
		background: #ccdb2a;
		border: none;
		margin: 0.5rem 0 0 1rem;
	}
}
