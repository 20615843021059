@import '../../../../styles/colors.scss';
@import '../../../../styles/font.scss';

.form-control {
	label {
		color: $grey-dark;
		font-size: 1.4rem;
		align-self: start;
		line-height: 1.6rem;
		margin-bottom: 0.25rem;
	}

	input,
	select,
	textarea,
	.div--file-upload {
		padding: 0 1.6rem;
		font-size: 1.8rem;
		min-height: 5rem;
		min-width: 8rem;
		border: $input-border;
		border-radius: 0.4rem;
	}

	textarea {
		padding: 1.6rem;
	}

	&--small {
		@extend .form-control;
		input,
		select,
		textarea {
			padding: 1rem;
			font-size: 1.6rem;
			min-height: 3rem;
			min-width: 6rem;
			border: $input-border;
		}
	}

	textarea {
		font-family: $family-gibson;
	}
}
