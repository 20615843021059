// margin
.m-0 {
	margin: 0;
}
.m-auto {
	margin: 0 auto;
}
.mr-auto {
	margin-right: auto;
}
.ml-auto {
	margin-left: auto;
}
.mb-1 {
	margin-bottom: 1rem;
}
.ml-1 {
	margin-left: 1rem;
}
.mr-1 {
	margin-right: 1rem;
}
.mx-2 {
	margin: 0 2rem;
}
.mt-1 {
	margin-top: 1rem;
}

// padding
.p-0 {
	padding: 0;
}
.p-1 {
	padding: 1rem;
}
.px-2 {
	padding-left: 2rem;
	padding-right: 2rem;
}

// other
.transparent {
	background: transparent;
	border: none;
}

.box-shadow {
	box-shadow: 0.2rem 0.2rem 0.8rem 0 hsl(0deg 0% 44% / 50%);
}

.z-2 {
	z-index: 2;
}

.decoration-none {
	text-decoration: none;
}
