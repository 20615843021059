.toggle-switch {
	height: 2rem;
	width: 3.5rem;
	border-radius: 2.7rem;
	background-color: #737373;
	padding: 0.3rem;
	border: none;

	&__selected {
		background-color: #00ab53;

		& > div {
			margin-left: auto;
		}
	}

	& > div {
		background-color: white;
		border-radius: 50%;
		width: 1.5rem;
		height: 100%;
	}
}
