$family-gibson: 'Gibson';
$family-bebas: 'Bebas Neue';
$family-noto: 'Noto Sans';

$font-small: 1.2rem;
$font-medium: 1.6rem;
$font-large: 1.8rem;
$font-xlarge: 3.2rem;

.gibson-small {
	font: normal $font-small $family-gibson;
}
.gibson-normal {
	font: normal $font-medium $family-gibson;
}
.gibson-large {
	font: normal $font-large $family-gibson;
}

.bebas-large {
	font: normal $font-large $family-bebas;
	letter-spacing: 0.13rem;
}

.noto-title {
	font: bold $font-xlarge $family-noto;
}

.bold {
	font-weight: bold;
}
