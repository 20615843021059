@import '../../../../styles/colors.scss';

.dropdown-input {
	border: $input-border;
	border-radius: 0.4rem;

	.item {
		padding: 1rem 1.6rem;
		min-height: 5.8rem;
		height: 5.8rem;
		display: flex;
		align-items: center;
		border-bottom: $input-border;

		&:last-child {
			border: none;
		}
	}

	&__list {
		max-height: 20rem;
		overflow-y: overlay;

		button {
			&:hover:enabled {
				background-color: $grey-light;
			}

			&:disabled {
				color: $grey-dark;
			}

			input {
				transform: scale(1.5);
				margin-right: 1.5rem;
				cursor: pointer;
				accent-color: $primary-blue;
			}
		}
	}
}
