@import '../../../../styles/colors.scss';

.dark-overlay {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	visibility: visible;
}

.modal {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	z-index: 2;
	padding: 2rem;
	border-radius: 1rem;

	.modal-close {
		transform: scale(1.5);
	}

	.modal-content {
		.auth-section {
			width: 80rem;
			max-height: 45rem;
			overflow-y: auto;
			overflow-x: hidden;

			&__groups,
			&__rounds {
				min-height: 25rem;
				flex: 0 0 auto;
				border: $input-border;
				border-radius: 0.4rem;
				padding: 2rem;

				&__player-section {
					min-height: 3rem;
					margin-bottom: 5rem;
					flex-wrap: wrap;

					span {
						background-color: $grey-light;
						padding: 0.8rem;
						border-radius: 2rem;
						width: 14.5rem;
					}
				}

				.auth-devices {
					position: absolute;
					left: 0;
					bottom: 0;
				}

				.qr-code {
					width: 15rem;
					height: 15rem;

					&__error {
						text-align: right;
						color: $error-main;
					}
				}
			}
		}

		.manage-device-limit {
			width: 21%;

			> button {
				min-height: 3.2rem;

				&:hover {
					background: $grey-light;
				}
			}

			&__input {
				border: 1px solid $grey-dark;

				input {
					min-height: 3rem;
					font-size: 1.4rem;
					padding: 0 0.8rem;
					border: none;
					width: 100%;

					&:focus {
						outline: none;
					}
				}

				button {
					width: 5rem;
					border: none;
					border-left: 1px solid $grey-dark;
					background: $grey-light;

					&:disabled > img {
						filter: brightness(0) saturate(100%) invert(85%) sepia(0%)
							saturate(358%) hue-rotate(148deg) brightness(91%) contrast(87%);
					}
				}

				.cancel-device > img {
					filter: brightness(0) saturate(100%) invert(31%) sepia(69%)
						saturate(1860%) hue-rotate(339deg) brightness(84%) contrast(96%);
				}
			}
		}
	}
}
