@import '../../../styles/colors.scss';

.group-details-entry {
	border: $input-border;
	padding: 2rem 1.5rem;
	border-radius: 0.4rem;
	width: 100%;

	&__player-section {
		min-height: 3rem;
		margin-bottom: 1rem;
		flex-wrap: wrap;

		span {
			background-color: $grey-light;
			padding: 0.8rem;
			border-radius: 2rem;
			width: 17.6rem;
		}
	}

	.add-player-button {
		height: max-content;
		padding: 2rem;
	}
}
