.toast {
	opacity: 0;
	z-index: 3;
	transition: opacity 0.5s ease-in-out;
	position: absolute;
	top: 5rem;
	left: 50%;
	transform: translate(-50%);
	padding: 1.6rem;
	color: white;
	border-radius: 0.4rem;

	& > span {
		margin-right: 10rem;
	}

	&__exit {
		position: absolute;
		top: 0.9rem;
		right: 0.7rem;

		img {
			filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(233deg)
				brightness(102%) contrast(105%);
		}
	}

	ul {
		padding-left: 1.6rem;
	}
}

.toast-open {
	opacity: 1;
}
.toast-success {
	background-color: #449926;
}

.toast-error {
	background-color: #ae1a1a;
}
