@import '../../../../styles/colors.scss';

.textarea-wrapper {
	.over-limit {
		color: $error-main;
	}

	.char-count {
		text-align: right;
	}
}
