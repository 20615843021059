@import '../../../styles/colors.scss';

.info-icon {
	border-radius: 50%;
	background: none;
	border: 2px solid $grey-medium;
	height: 2rem;
	width: 2rem;
	color: $grey-medium;
	position: relative;

	&:hover .hover-text {
		visibility: visible;
	}

	.hover-text {
		visibility: hidden;
		word-wrap: break-word;
		background-color: $primary-blue;
		color: $neutral-main;
		border-radius: 0.4rem;
		padding: 0.5rem 1rem;
		width: 20rem;
		text-align: left;

		/* Position the tooltip */
		position: absolute;
		left: -1rem;
		top: 2.5rem;
		z-index: 1;

		& > div {
			border: solid 10px transparent;
			border-right-color: $primary-blue;
			position: absolute;
			left: 0.5rem;
			top: -1.5rem;
			transform: rotate(90deg);
		}
	}

	&__small {
		height: 1.4rem;
		width: 1.4rem;
		border-width: 1px;

		& > span {
			font-size: 1.2rem;
		}
	}
}
