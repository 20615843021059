@import '../../../styles/colors.scss';

.manage-challenge {
	.hole-details,
	.group-details {
		margin-top: 4rem;
	}
	.empty-list {
		display: flex;
		height: 13.2rem;
		justify-content: center;
		align-items: center;
		background-color: $grey-light;
	}

	.hole-entries > div,
	.group-entries > div {
		margin-bottom: 2rem;
	}
}
