@import '../../../../styles/colors.scss';

.loader {
	border: 16px solid $grey-light;
	border-top: 16px solid $primary-blue;
	border-radius: 50%;
	width: 12rem;
	height: 12rem;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
